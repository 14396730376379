import {
    React,
    useState,
    forwardRef,
    useRef,
    useImperativeHandle,
} from "react";
import "./navbar.css";
import axios from "axios";

import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import SaveWebsiteDialog from "./SaveWebsiteDialog";
import LoadWebsiteDialog from "./LoadWebsiteDialog";
import { DOMAIN } from "./urlcfg";

export default function Navbar(props) {

    const saveWebsite = (name) => {
        // Name must be valid
        // - not empty
        // - not whitespace
        // - between 1 and 32 characters
        // - only alphanumeric characters and underscores
        let isValid = validateName(name);

        if (!isValid.valid) {
            toast.error(`Invalid name: ${isValid.reason}`, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
             });
            return;
        }

        // Get data
        let data = props.getData();

        let obj = {
            name: name,
            data: data
        };

        console.log("Saving", obj);

        // Send data to server and display promise toast
        const pending = toast.loading(`Saving ${name}..`, {
            position: "bottom-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        
        axios.post(`${DOMAIN}/save`, obj)
            .then(res => {
                toast.dismiss(pending);
                toast.success(`Saved ${name}!`, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                console.log(res)
            })
            .catch(err => {
                toast.dismiss(pending);
                toast.error(`Failed to save ${name}!`, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                console.log(err)
            });
    }

    const SaveWebsiteModalRef = forwardRef((refProps, ref) => {
        const [open, setOpen] = useState(false);

        useImperativeHandle(ref, () => ({
            openModal() {
                setOpen(true);
                props.setWebsiteName(props.websiteName);
            },
            closeModal() {
                console.log("Closing");
                setOpen(false);
            }
        }));

        return (
            <SaveWebsiteDialog open={open} closeModal={() => setOpen(false)} 
                saveWebsite={saveWebsite}
                websiteName={props.websiteName}
                setWebsiteName={props.setWebsiteName}
            />
        )
    });

    const LoadWebsiteModalRef = forwardRef((props, ref) => {
        const [open, setOpen] = useState(false);
        const [websites, setWebsites] = useState([]);
        const [loaded, setLoaded] = useState(false);

        useImperativeHandle(ref, () => ({
            openModal() {
                setOpen(true);
                axios.get(`${DOMAIN}/websites`)
                    .then(res => {
                        let data = res.data;

                        if (!data.success) {
                            toast.error(`Failed to load websites!`, {
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            return;
                        }

                        let websiteList = data.websites;
                        // sort by date
                        websiteList.sort((a, b) => {
                            return new Date(b.date) - new Date(a.date);
                        });

                        setWebsites(websiteList);
                        setLoaded(true);
                    })
                    .catch(err => {
                        console.log(err);
                        setLoaded(true);
                    });
            },
            closeModal() {
                setOpen(false);
                setLoaded(false);
                setWebsites([]);
            }
        }));

        return (
            <LoadWebsiteDialog
                open={open}
                closeModal={() => setOpen(false)}
                websites={websites}
                loaded={loaded}
                setLoaded={setLoaded}
                setData={setData}
                setWebsiteName={props.setWebsiteName}
                loadWebsites={() => {
                    axios.get(`${DOMAIN}/websites`)
                    .then(res => {
                        let data = res.data;

                        if (!data.success) {
                            toast.error(`Failed to load websites!`, {
                                position: "bottom-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            return;
                        }

                        let websiteList = data.websites;
                        // sort by date
                        websiteList.sort((a, b) => {
                            return new Date(b.date) - new Date(a.date);
                        });

                        setWebsites(websiteList);
                        setLoaded(true);
                    })
                    .catch(err => {
                        console.log(err);
                        setLoaded(true);
                    });
                }}
            />
        )
    });

    const openSaveWebsiteModal = () => {
        SaveWebsiteModalRef.current.openModal();
    }

    const openLoadWebsiteModal = () => {
        LoadWebsiteModalRef.current.openModal();
    }

    const newWebsite = () => {
        props.newWebsite();
    }

    const ping = () => {
        axios.get(`${DOMAIN}/ping`)
            .then(res => {
                toast.success("Pong!", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch(err => {
                toast.error(err, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    }

    const setData = (data, name) => {
        props.setData(data, name);
    }

    return (
        <>
            {/* <nav>
                <div className="navbar">
                    <button className="new-btn" onClick={newWebsite}>New website</button>
                    <button className="load-btn" onClick={openLoadWebsiteModal}>Load website</button>
                    <button className="save-btn" onClick={openSaveWebsiteModal}>Save website</button>
                    <button className="ping-btn" onClick={ping}>Ping</button>
                </div>
            </nav> */}

            <nav>
                <div className="navbar">
                    <div className="navbar-left">
                        <div className="logo">
                            <img src="/logo.webp" className="logo-img" />
                            <h1 className="title">Calculate Website Cost<span className="dot">.</span></h1>
                        </div>
                        <div className="website-name">
                            <h3 className="name">{
                                props.websiteName === "" ? "Untitled Website" : props.websiteName
                            }</h3>
                        </div>
                    </div>

                    <div className="navbar-right">
                        <button className="new-btn" onClick={newWebsite}>New website</button>
                        <button className="load-btn" onClick={openLoadWebsiteModal}>Load website</button>
                    </div>
                </div>
            </nav>

            <SaveWebsiteModalRef ref={SaveWebsiteModalRef} />
            <LoadWebsiteModalRef ref={LoadWebsiteModalRef} />
        </>
    )
}

function validateName(name) {
    // check if name is empty
    if (name === "") {
        return { valid: false, reason: "Name cannot be empty" };
    }

    // or just whitespace (trim)
    if (name.trim() === "") {
        return { valid: false, reason: "Name cannot be empty" };
    }

    // check if name is too short/long
    if (name.length < 3 || name.length > 20) {
        return { valid: false, reason: "Name must be between 3 and 20 characters" };
    }

    // check if name contains invalid characters
    if (!/^[a-zA-Z0-9]+$/.test(name)) {
        return { valid: false, reason: "Name must only contain letters and numbers" };
    }

    return { valid: true, reason: "" };
}