import React from "react";
import "./hosting.css";
import "./pricesection.css";
import Input from "./Input";
import Select from 'react-select';


function HostingOption(props) {
    return (
        <>
            {
                // disk quota
                props.diskQuota != null ?
                <span className="quota">{props.diskQuota}</span> :
                <span className="quota">0GB</span>
            }
            <p className="item-name">{props.type} {
                props.type === "None" ? "" : "Hosting"
            }</p>
        </>
    );
}


export default function Hosting(props) {
    // Radio button
    const [hostingCost, setHostingCost] = React.useState(0);

    // Set radio button
    const setRadioBtn = (val) => {
        // get index
        let index = props.hostingTypes.findIndex((item) => item.type == val.type);
        
        // if eCommerce is checked, index must be 3 or over (or not at all)
        if (props.eCommerce) {
            if (index && (index < 3)) index = 3;
        }

        props.setHostingType(index);
    }

    // Handle cost change
    const handleCostChange = (e) => {
        console.log("HOSTING TYPES", props.hostingTypes);
        let value = e.target.value;

        // If value is not an integer, return
        if (isNaN(value)) return;

        // value = parseFloat(value).toFixed(2);

        setHostingCost(value);
        props.setHostingCost(value);
    }

    return (
        <div className="hosting price-section">
            <span className="label">Hosting</span>
            <div className="hosting-content">
                <button
                    className={
                        props.hostingPeriod == "monthly" ?
                        "active" : ""
                    }
                    onClick={() => props.setHostingPeriod("monthly")}
                >
                    <h5 className="text">Monthly Price</h5>
                    <h5 className="price">£{
                        props.hostingTypes[props.hostingType].monthlyCost
                    } per month</h5>
                </button>

                <button
                    className={
                        props.hostingPeriod == "annually" ?
                        "active" : ""
                    }
                    onClick={() => props.setHostingPeriod("annually")}
                >
                    <h5 className="text">Annual Price</h5>
                    <h5 className="price">£{
                        props.hostingTypes[props.hostingType].annualCost
                    } per year</h5>
                </button>

                <Select
                    value={props.hostingTypes[props.hostingType]}
                    onChange={setRadioBtn}
                    options={props.hostingTypes}
                    getOptionLabel={(option) => <HostingOption
                                                    type={option.type}
                                                    diskQuota={option.diskQuota}
                                                />}
                    getOptionValue={(option) => option.type}
                    className="hosting-select"
                    classNamePrefix="hosting-select"
                />

                {
                    props.hostingType == 6 ?
                    <div className="mega-input-container">
                        <input
                            type="number"
                            className={
                                "mega-input" +
                                // unfilled class if value is empty
                                (hostingCost == 0 ? " unfilled" : "")
                            }
                            placeholder="Enter Mega hosting cost"
                            value={hostingCost}
                            onChange={handleCostChange}
                        />
                        <span className="mega-input-label">£</span>
                    </div> :
                    <></>
                }
            </div>
        </div>
    )
}