import React from "react";
import "./packages.css";
import "./pricesection.css";
import "./checkboxSelect.css";

export default function Packages(props) {
    // Radio button
    const [radio, setRadio] = React.useState(0);

    // Set radio button
    const setRadioBtn = (idx) => {
        setRadio(idx);
        props.setPackageType(idx);
    }

    return (
        <div className="packages price-section">
            <span className="label">SEO Packages</span>
            <div className="packages-content">
                {props.packages.map((item, index) => {
                    return (
                        // <div className="checkboxSelect" key={index}>
                        //     <input
                        //         type="radio"
                        //         name="package"
                        //         id={item.name}
                        //         value={index}
                        //         checked={radio == index}
                        //         onChange={setRadioBtn}
                        //     />
                        //     <label htmlFor={item.name}>
                        //         <div className="checkboxSelect-name">{item.name}</div>
                        //         <div className="checkboxSelect-price">£{item.price} per month</div>
                        //     </label>
                        // </div>

                        <div className={
                            "checkboxSelect noselect "
                            + (radio == index ? "selected" : "")
                        }
                            key={index}
                            onClick={() => setRadioBtn(index)}
                        >
                            <div className="checkboxSelect-circle"></div>
                            <div className="checkboxSelect-name">
                                {item.name}<br />
                                <span className="checkboxSelect-price">
                                £{item.price} a month
                                </span>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>            
    )
}