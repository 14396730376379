import React from "react";

export default function Additions(props) {
    return (
        <div className="additions price-section">
            <span className="label">Additions</span>
            <div className="additions-content">
                {props.additionsCosts.map((item, index) => {
                    return (
                        <div className={
                            "checkboxSelect noselect "
                            + (props.additions.includes(index) ? "selected" : "")
                            + (item.hidden ? " hidden" : "")
                        }
                            key={index}
                            // setAdditions(value, checked)
                            onClick={() => props.setAdditions(index, !props.additions.includes(index))}
                        >
                            <div className="checkboxSelect-square">
                                <svg
                                    className="checkboxSelect-checkmark"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M9.172 16.828l-4.172-4.172-1.414 1.414 5.586 5.586 12-12-1.414-1.414z"
                                    />
                                </svg>
                            </div>
                            <div className="checkboxSelect-name">
                                {item.name}
                                <span className="checkboxSelect-price">
                                    +£{item.price} {
                                        item.interval ? <span>/{item.interval}</span> : null
                                    }
                                </span>
                            </div>
                        </div>
                    )
                }
                )}
            </div>
        </div>
    )
}