import React from "react";
import "./SSL.css";
import "./checkboxSelect.css";

export default function SSL(props) {
    return (
        <div className="packages price-section">
            <span className="label">SSL & GDPR</span>
            <div className="ssl-content">
                {
                    props.sslTypes.map((item, index) => {
                        return (
                            // <div className="checkboxSelect" key={index}>
                            //     <input
                            //         type="radio"
                            //         name={item.type}
                            //         id={"ssl-" + item.type}
                            //         value={index}
                            //         checked={props.sslType == index}
                            //         onChange={(e) => props.setSSLType(e.target.value)}
                            //     />
                            //     <label htmlFor={item.type}>
                            //         <div className="checkboxSelect-name">{item.type}</div>
                            //         <div className="checkboxSelect-price">£{
                            //             props.eCommerce ? item.eCommerceAnnualCost : item.annualCost
                            //         } per year</div>
                            //     </label>
                            // </div>

                            <div className={
                                "checkboxSelect noselect"
                                + (props.sslType == index ? " selected" : "")
                                + (item.deprecated ? " deprecated" : "")
                            }
                                key={index}
                                onClick={() => !item.deprecated && props.setSSLType(index)}
                            >
                                <div className="checkboxSelect-circle"></div>
                                <div className="checkboxSelect-name">
                                    {item.type}<br />
                                    <span className="checkboxSelect-price">
                                    £{
                                        props.eCommerce ? item.eCommerceAnnualCost : item.annualCost
                                    } a year
                                    </span>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}