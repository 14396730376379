import React from "react";
import "./table.css";

export default function Table(props) {
    return (
        <div className="table__container">
            {
                props.data.map((item, index) => {
                    if (item.price !== 0) {
                        return (
                            <div className={
                                "table__row" + (item.price < 0 ? " negative" : "")
                            } key={index}>
                                <div className="table__cell">{item.name}</div>
                                <div className="table__cell">{formatter.format(item.price)}</div>
                            </div>
                        );
                    }
                    return null;
                })
            }

            {
                // bold line and extra rows
                props.finalData.map((item, index) => {
                    if (item.price !== 0) {
                        return (
                            <div className={
                                "table__row bold_small" + (item.price < 0 ? " negative" : "")
                                + (item.bold ? " bold" : "")
                                + (index == 0 ? " overline_row" : "")
                            } key={index}>
                                <div className="table__cell">{item.name}</div>
                                <div className="table__cell">{formatter.format(item.price)}</div>
                            </div>
                        );
                    }
                    return null;
                })
            }
        </div>
    );
}

const formatter = new Intl.NumberFormat('en-UK', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });