import React from "react";
import "./siteManagement.css";
import "./checkboxSelect.css";

export default function SiteManagement(props) {
    const setCarePackage = (idx) => {
        props.setCarePackage(idx);
    };

    return (
        <div className="additions price-section">
            <span className="label">Site Management</span>
            <div className="additions-content">
                {
                    props.carePackages.map((item, index) => {
                        return (
                            <div className={
                                "checkboxSelect noselect "
                                + (props.carePackage == index ? "selected" : "")
                            }
                                key={index}
                                onClick={() => setCarePackage(index)}
                            >
                                <div className="checkboxSelect-circle"></div>
                                <div className="checkboxSelect-name">
                                    {item.name}
                                    <span className="checkboxSelect-price">
                                    {
                                        item.interval ? <span><br />£{item.price} {item.interval}</span> : null
                                    }
                                    </span>
                                </div>
                            </div>
                        )
                    })
                }

                {props.siteManagementCosts.map((item, index) => {
                    return (
                        // <div className="addition" key={index}>
                        //     <input
                        //         type="checkbox"
                        //         name="addition"
                        //         id={item.name}
                        //         value={index}
                        //         onChange={props.setAdditions}
                        //     />
                        //     <label htmlFor={item.name}>
                        //         <div className="addition-name">{item.name}</div>
                        //         <div className="addition-price">£{item.price}
                        //             {
                        //                 item.interval ? <span>/{item.interval}</span> : null
                        //             }
                        //         </div>
                        //     </label>
                        // </div>

                        <div className={
                            "checkboxSelect noselect "
                            + (props.additions.includes(index + 7) ? "selected" : "")
                        }
                            key={index}
                            onClick={() => props.setAdditions(index + 7, !props.additions.includes(index + 7))}
                        >
                            <div className="checkboxSelect-square">
                                <svg
                                    className="checkboxSelect-checkmark"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M9.172 16.828l-4.172-4.172-1.414 1.414 5.586 5.586 12-12-1.414-1.414z"
                                    />
                                </svg>
                            </div>
                            <div className="checkboxSelect-name">
                                {item.name}
                                <span className="checkboxSelect-price">
                                    +£{item.price} {
                                        item.interval ? <span>/{item.interval}</span> : null
                                    }
                                </span>
                            </div>
                        </div>
                    )
                }
                )}

                <hr />
            </div>
        </div>
    )
}