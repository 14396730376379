import React from "react";
import ReactSlider from "react-slider";

import "./discounts.css";

export default function Discounts(props) {
    return (
        <div className="discounts">
            <h2
                className="label"
            >Price adjust</h2>
            <div className="discounts-content">
                {/* <input
                    type="range"
                    name="discount"
                    id="discount"
                    min="0"
                    max="100"
                    value={props.discount}
                    onChange={(e) => {
                        const value = e.target.value;
                        if (isNaN(value)) return;
                        props.setDiscount(value);
                    }}
                />

                <div className="discount-value">
                    {props.discount}% off
                    {
                        props.discount > 90 ? <span>😯</span> : null
                    }
                </div> */}

                <ReactSlider
                    className="slider"
                    thumbClassName="thumb"
                    trackClassName="track"
                    renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                    renderTrack={(props, state) => <div {...props} index={state.index} />}
                    defaultValue={props.discount}
                    onChange={(value) => {
                        props.setDiscount(value);
                    }}
                    marks
                    marksClassName="marks"
                    min={90}
                    max={200}
                />
            </div>
        </div>
    )
}