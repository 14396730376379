import React, { useState } from "react";
import Input from "./Input";
import "./eCommerce.css";

export default function ECommerce(props) {
    const [saveProducts, setSaveProducts] = useState(0);
    const [saveProductCategories, setSaveProductCategories] = useState(0);

    const handleECommerce = () => {
        let current = props.eCommerce;
        props.setECommerce(!current);

        // reset values if checkbox is unchecked
        if (!current) {
            // save values
            setSaveProducts(props.products);
            setSaveProductCategories(props.productCategories);

            props.setProducts(0);
            props.setProductCategories(0);
        } else {
            // restore values
            props.setProducts(saveProducts);
            props.setProductCategories(saveProductCategories);
        }
    };

    return (
        <div className="eCommerce">
            {/* checkbox to enable category */}
            <div className="checkboxContainer">
                <h5 className="name">ECommerce</h5>
                <div className={
                    "checkbox noselect " + (props.eCommerce ? "" : "off")
                }
                    onClick={handleECommerce}
                >
                    <div className="content">    
                        <span className="cb-label">Yes</span>
                        <svg className="cb-circle">
                            <circle cx="50%" cy="50%" r="50%"></circle>
                        </svg>
                        <span className="cb-label">No</span>
                    </div>
                </div>
            </div>

            {/* input for number of products */}
            <div className="eCommerce-input"
                style={{
                    display: props.eCommerce ? "block" : "none"
                }}
            >
                <div className="count-input">
                    <label htmlFor="products">Number of products</label>
                    <Input
                        name="products"
                        value={props.products}
                        setValue={props.setProducts}
                    />
                </div>

                {
                    props.products > 1500 ? (
                        <div className="warning">
                            <span>Site should be custom quoted due to a large quantity of products</span>
                        </div>
                    ) : null
                }

                {/* <div className="count-input">
                    <label htmlFor="product-categories">Product categories</label>
                    <Input 
                        name="product-categories"
                        value={props.productCategories}
                        setValue={props.setProductCategories}
                    />
                </div> */}
            </div>
        </div>
    )
}