import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import "./calculator.css";

import WebDetails from "./WebDetails";
import Navbar from "./Navbar";
import Packages from "./Packages";
import SSL from "./SSL";
import Hosting from "./Hosting";
import Additions from "./Additions";
import Discounts from "./Discounts";
import SiteManagement from "./SiteManagement";
import SaveWebsiteDialog from "./SaveWebsiteDialog";
import DownloadQuoteDialog from "./DownloadQuoteDialog";
import Table from "./Table";

import axios from "axios";
import { DOMAIN } from "./urlcfg";
import { toast, ToastContainer } from "react-toastify";
import {
    packages,
    sslTypes,
    hostingTypes,
    additionsCosts,
    carePackages,
    siteManagementCosts
} from "./constants";

import "./checkboxSelect.css";

const CALCULATE_URL = `${DOMAIN}/calculate`;

export default function Calculator() {
    // Website details
    // Is eCommerce?
    const [eCommerce, setECommerce] = useState(false);
    // Number of products
    const [products, setProducts] = useState(0);
    // Number of product categories
    const [productCategories, setProductCategories] = useState(0);
    // Number of pages
    const [pages, setPages] = useState(1);
    // Number of posts
    const [posts, setPosts] = useState(0);
    // Number of taxonomies using post format
    const [taxonomies, setTaxonomies] = useState(0);
    // Number of contact forms
    const [contactForms, setContactForms] = useState(0);

    // Set package type
    const [packageType, setPackageType] = useState(0);
    // Set SSL type
    const [sslType, setSSLType] = useState(0);
    // Set hosting type
    const [hostingType, setHostingType] = useState(0);
    // Mega hosting cost
    const [hostingCost, setHostingCost] = useState(0);
    // Hosting period
    const [hostingPeriod, setHostingPeriod] = useState("annually");

    // Additions
    const [additions, setAdditions] = useState([]);

    // Number of animated graphics
    const [animatedGraphics, setAnimatedGraphics] = useState(0);
    // Number of graphics
    const [graphics, setGraphics] = useState(0);

    // Care packages
    const [carePackage, setCarePackage] = useState(0);

    // Discount multiplier
    const [discount, setDiscount] = useState(100);

    // Final cost
    const [finalCost, setFinalCost] = useState(0);
    // Breakdown
    const [breakdown, setBreakdown] = useState([]);
    // Monthly cost
    const [monthlyCost, setMonthlyCost] = useState(0);
    // Monthly cost breakdown
    const [monthlyBreakdown, setMonthlyBreakdown] = useState([]);
    // Annual cost
    const [annualCost, setAnnualCost] = useState(0);
    // Annual cost breakdown
    const [annualBreakdown, setAnnualBreakdown] = useState([]);
    // Subtotal breakdown
    const [subtotalBreakdown, setSubtotalBreakdown] = useState({
        oneTime: 0, monthly: 0, annual: 0
    });
    // VAT breakdown
    const [vatBreakdown, setVATBreakdown] = useState({
        oneTime: {name: "VAT", cost: 0}, monthly: {name: "VAT", cost: 0}, annual: {name: "VAT", cost: 0}
    });
    // Discount breakdown
    const [discountBreakdown, setDiscountBreakdown] = useState({
        oneTime: {name: "Discount", cost: 0}, monthly: {name: "Discount", cost: 0}, annual: {name: "Discount", cost: 0}
    });

    // Current name
    const [websiteName, setWebsiteName] = useState("");

    // handle addition change
    const handleAdditionChange = (value, checked) => {

        // If checked, add to additions
        if (checked) {
            console.log("value is", 6);
            if (value >= 6) {
                // overflows into siteManagementCosts (value - 6)
                setAdditions([...additions, value]);
            } else {
                setAdditions([...additions, value]);
                
                let name = additionsCosts[value].name;
                if (name == "eCommerce SSL" || name == "eCommerce GDPR Package (inc SSL)") {
                    // get hosting type
                    let hosting = hostingTypes[hostingType].type;
                    // if hosting is one page or small, set to medium
                    if (hosting == "One page" || hosting == "Small" || hosting == "None") {
                        setHostingType(3);
                        
                        // setTimeout(() => {
                        //     document.getElementById("Medium").checked = true;
                        // }, 100);

                        // notify
                        toast.info("Hosting type changed to Medium for eCommerce", {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }
            }
        } else {
            // If not checked, remove from additions
            setAdditions(additions.filter(item => item != value));
        }
    }

    const setData = (data, name) => {
        setWebsiteName(name);
        // set package type to index
        const packageIndex = packages.findIndex(item => item.name == data.packageType);
        setPackageType(packageIndex);
        // set ssl type to index
        const sslIndex = sslTypes.findIndex(item => item.type == data.sslType);
        setSSLType(sslIndex);
        // set hosting type to index
        const hostingIndex = hostingTypes.findIndex(item => item.type == data.hostingType);
        setHostingType(hostingIndex);
        setHostingCost(data.hostingCost);
        // set additions to index
        const additionsIndexes = data.additions.map(item => {
            return additionsCosts.findIndex(addition => addition.name === item.name);
          });
        setHostingPeriod(data.hostingPeriod);
        setAdditions(additionsIndexes);
        setPages(data.pages);
        setAnimatedGraphics(data.animatedGraphics);
        setGraphics(data.graphics);
        setTaxonomies(data.taxonomies);
        setContactForms(data.contactForms);
        setProducts(data.products);
        setProductCategories(data.productCategories);
        setDiscount(data.discount);
        setECommerce(data.eCommerce);
        setPosts(data.posts);

        // set care package to index
        const carePackageIndex = carePackages.findIndex(item => item.name == data.carePackage.name);
        setCarePackage((carePackageIndex != -1) ? carePackageIndex : 0);

        
        if (name === "") {
            toast.success(`Reset options`, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        } else {
            toast.success(`Loaded ${name}`, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
        }
    }

    const getData = () => {
        if (sslType === -1) {
            setSSLType(0);
        }

        if (hostingPeriod === undefined) {
            setHostingPeriod("annually");
        }

        console.log("packages and type", packages, packageType);
        
        return {
            packageType: packages[packageType].name,
            sslType: sslType === -1 ? 0 : sslTypes[sslType],
            eCommerce: eCommerce,
            hostingType: hostingTypes[hostingType].type,
            hostingCost: hostingCost,
            hostingPeriod: hostingPeriod === undefined ? "annually" : hostingPeriod,
            additions: additions.map(addition => {
                return additionsCosts[addition]
            }),
            pages: pages,
            animatedGraphics: animatedGraphics,
            graphics: graphics,
            taxonomies: taxonomies,
            contactForms: contactForms,
            products: products,
            productCategories: productCategories, // deprecated
            posts: posts,
            discount: discount,
            carePackage: carePackages[carePackage],
        };
    }

    const reset = () => {
        setData({
            packageType: "None",
            sslType: sslTypes[0],
            eCommerce: false,
            hostingType: "None",
            hostingCost: 0,
            additions: [],
            pages: 1,
            animatedGraphics: 0,
            posts: 0,
            graphics: 0,
            taxonomies: 0,
            contactForms: 0,
            products: 0,
            productCategories: 0,
            discount: 100,
        }, "");
    }

    // any time something changes, update the data
    useEffect(() => {
        const data = getData();
        
        console.log(data);

        axios.post(CALCULATE_URL, data)
            .then(res => {
                if (!res.status === 200) {
                    throw new Error(`Calculate POST failed: ${res}`);
                }

                return res.data;
            })
            .then((data) => {
                console.log(data);
                setFinalCost(data.oneTimeCost.cost);
                setMonthlyCost(data.monthlyCost.cost);
                setAnnualCost(data.annualCost.cost);

                // set subtotals
                setSubtotalBreakdown({
                    oneTime: data.oneTimeCost.subtotal,
                    monthly: data.monthlyCost.subtotal,
                    annual: data.annualCost.subtotal,
                });
                
                let oneTimeBreakdown = data.oneTimeCost.breakdown;
                oneTimeBreakdown.sort((a, b) => {
                    return a.cost - b.cost;
                });

                let monthlyBreakdown = data.monthlyCost.breakdown;
                monthlyBreakdown.sort((a, b) => {
                    return a.cost - b.cost;
                });

                let annualBreakdown = data.annualCost.breakdown;
                annualBreakdown.sort((a, b) => {
                    return a.cost - b.cost;
                });

                // dont include if:
                // - name includes "VAT"
                // - name includes "Discount"
                // - name includes "Price adjust"
                // - name is "None"
                // - price is 0
                let oneTimeBreakdownFiltered = oneTimeBreakdown.filter(item => {
                    return !item.name.includes("VAT") && !item.name.includes("Discount") && !item.name.includes("Price adjust") && item.name !== "None" && item.cost !== 0;
                });
                
                let monthlyBreakdownFiltered = monthlyBreakdown.filter(item => {
                    return !item.name.includes("VAT") && !item.name.includes("Discount") && !item.name.includes("Price adjust") && item.name !== "None" && item.cost !== 0;
                });

                let annualBreakdownFiltered = annualBreakdown.filter(item => {
                    return !item.name.includes("VAT") && !item.name.includes("Discount") && !item.name.includes("Price adjust") && item.name !== "None" && item.cost !== 0;
                });

                setBreakdown(oneTimeBreakdownFiltered);
                setMonthlyBreakdown(monthlyBreakdownFiltered);
                setAnnualBreakdown(annualBreakdownFiltered);

                // log the breakdowns
                console.log("One time breakdown", oneTimeBreakdownFiltered);
                console.log("Monthly breakdown", monthlyBreakdownFiltered);
                console.log("Annual breakdown", annualBreakdownFiltered);

                // set VAT, discount, price adjust
                setVATBreakdown({
                    oneTime: oneTimeBreakdown.find(item => item.name.includes("VAT")),
                    monthly: monthlyBreakdown.find(item => item.name.includes("VAT")),
                    annual: annualBreakdown.find(item => item.name.includes("VAT")),
                });

                setDiscountBreakdown({
                    oneTime: oneTimeBreakdown.find(item => item.name.includes("Discount") || item.name.includes("Price adjust")),
                    monthly: monthlyBreakdown.find(item => item.name.includes("Discount") || item.name.includes("Price adjust")),
                    annual: annualBreakdown.find(item => item.name.includes("Discount") || item.name.includes("Price adjust")),
                });
            })
            .catch(err => {
                console.log(err);
                // toast.error(`Error: ${err}`, {
                //     position: toast.POSITION.BOTTOM_RIGHT
                // });
                throw new Error(`Calculate POST failed: ${err}`);
            });
    }, [
        packageType, sslType, eCommerce, hostingType, hostingCost,
        hostingPeriod, additions, pages, animatedGraphics, graphics,
        taxonomies, contactForms, products, productCategories, discount,
        carePackage, posts
    ]);

    const onSetEcommerce = (e) => {
        setECommerce(e);
        // force hosting to be atleast index 3
        console.log("ht", hostingType);
        if (hostingType < 3) {
            setHostingType(3);
            toast.info("Hosting type changed to Medium for eCommerce", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    // downloadQuote function
    // async
    const downloadQuote = async () => {
        if (websiteName === "") {
            toast.error(`Website is currently unsaved`, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        // open modal
        DownloadQuoteModalRef.current.openModal();
    }

    const saveQuote = () => {
        // if no website name, prompt for one
        console.log(`Saving quote for "${websiteName}"`)
        if (websiteName === "") {
            SaveWebsiteModalRef.current.openModal();
        } else {
            // save quote
            saveWebsite(websiteName);
        }
    }

    const deleteQuote = () => {
        if (websiteName === "") {
            toast.error(`Website is currently unsaved`, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        axios.delete(`${DOMAIN}/websites/${websiteName}`)
            .then(res => {
                if (!res.data.success) {
                    toast.error(`Failed to delete website!`, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    return;
                }

                toast.success(`Deleted website!`, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch(err => {
                console.log(err);
                toast.error(`Failed to delete website!`, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })

        reset();
    }

    const SaveWebsiteModalRef = forwardRef((refProps, ref) => {
        const [open, setOpen] = useState(false);

        useImperativeHandle(ref, () => ({
            openModal() {
                setOpen(true);
                setWebsiteName(websiteName);
            },
            closeModal() {
                console.log("Closing");
                setOpen(false);
            }
        }));

        return (
            <SaveWebsiteDialog open={open} closeModal={() => setOpen(false)} 
                saveWebsite={saveWebsite}
                websiteName={websiteName}
                setWebsiteName={setWebsiteName}
            />
        )
    });

    const DownloadQuoteModalRef = forwardRef((refProps, ref) => {
        const [open, setOpen] = useState(false);

        useImperativeHandle(ref, () => ({
            openModal() {
                setOpen(true);
            },
            closeModal() {
                console.log("Closing");
                setOpen(false);
            }
        }));

        return (
            <DownloadQuoteDialog open={open} closeModal={() => setOpen(false)}
                websiteName={websiteName}
                pages={pages}
                subtotalCost={subtotalBreakdown.oneTime}
                vatCost={vatBreakdown.oneTime.price}
                discountCost={
                    discountBreakdown.oneTime === undefined
                        ? 0
                        : discountBreakdown.oneTime.price
                }
                finalCost={finalCost}
                additions={additions}
                carePackage={carePackage}
                sslType={sslType}
                hostingType={hostingType}
                seoPackage={packageType}
                ecommerce={eCommerce}

                // breakdowns
                oneTimeBreakdown={breakdown}
                monthlyBreakdown={monthlyBreakdown}
                annualBreakdown={annualBreakdown}
            />
        )
    });

    const saveWebsite = (name) => {
        // Name must be valid
        // - not empty
        // - not whitespace
        // - between 1 and 32 characters
        // - only alphanumeric characters and underscores
        let isValid = validateName(name);

        if (!isValid.valid) {
            toast.error(`Invalid name: ${isValid.reason}`, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
             });
            return;
        }

        // Get data
        let data = getData();

        let obj = {
            name: name,
            data: data
        };

        console.log("Saving", obj);

        // Send data to server and display promise toast
        const pending = toast.loading(`Saving ${name}..`, {
            position: "bottom-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        
        axios.post(`${DOMAIN}/save`, obj)
            .then(res => {
                toast.dismiss(pending);
                toast.success(`Saved ${name}!`, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                console.log(res)
            })
            .catch(err => {
                toast.dismiss(pending);
                toast.error(`Failed to save ${name}!`, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                console.log(err)
            });
    }

    return (
        <div className="page calc-page">
            <div className="bg"
                style={{backgroundImage: `url(${process.env.PUBLIC_URL}/Gradient-BG.webp)`,}}
            ></div>

            <ToastContainer />
            <Navbar 
                getData={getData}
                setData={setData}
                websiteName={websiteName}
                setWebsiteName={setWebsiteName}
                newWebsite={() => {
                    reset();
                }}
            />

            <div className="content">
                <div className="pane settings">

                    <div className="settings-content">
                        <div className="split-around">
                            <WebDetails
                                eCommerce={eCommerce}
                                setECommerce={onSetEcommerce}
                                products={products}
                                setProducts={setProducts}
                                productCategories={productCategories}
                                setProductCategories={setProductCategories}
                                pages={pages}
                                setPages={setPages}
                                posts={posts}
                                setPosts={setPosts}
                                taxonomies={taxonomies}
                                setTaxonomies={setTaxonomies}
                                contactForms={contactForms}
                                setContactForms={setContactForms}
                            />

                            <Additions
                                additions={additions}
                                setAdditions={handleAdditionChange}
                                additionsCosts={additionsCosts}
                            />

                            <SSL
                                sslType={sslType}
                                setSSLType={setSSLType}
                                sslTypes={sslTypes}
                                eCommerce={eCommerce}
                            />
                        </div>

                        <div className="split">
                            <Hosting
                                hostingTypes={hostingTypes}
                                hostingType={hostingType}
                                setHostingType={setHostingType}
                                hostingCost={hostingCost}
                                setHostingCost={setHostingCost}
                                hostingPeriod={hostingPeriod}
                                setHostingPeriod={setHostingPeriod}
                                eCommerce={eCommerce}
                            />

                            <SiteManagement
                                siteManagementCosts={siteManagementCosts}
                                setAdditions={handleAdditionChange}
                                carePackages={carePackages}
                                setCarePackage={setCarePackage}
                                carePackage={carePackage}
                                additions={additions}
                            />
                            
                            <Packages 
                                packages={packages}
                                packageType={packageType}
                                setPackageType={setPackageType}
                            />
                        </div>

                        <div className="split-around">
                            <>
                                <Discounts
                                    discount={discount}
                                    setDiscount={setDiscount}
                                />
                            </>
                        </div>
                    </div>

                </div>
                <div className="pane finalcosts">
                    <h3
                        className="text-2xl font-bold title"
                    >Final cost</h3>
                    <h1 className="finalCostPrice">{formatter.format(finalCost)}</h1>
                    {
                        monthlyCost !== 0 ? (
                            <h3 className="finalCostPriceSmall">{formatter.format(monthlyCost)} per month following</h3>
                        ) : null
                    }
                    {
                        annualCost !== 0 ? (
                            <h3 className="finalCostPriceSmall">{formatter.format(annualCost)} per year following</h3>
                        ) : null
                    }
                    <span className="finalCostPriceSmaller">GBP • Exclusive of VAT</span>

                    <br />

                    <h3 className="breakdown-title">Cost Breakdown</h3>
                    <Table
                        data={breakdown}
                        finalData={[
                            {
                                name: "Subtotal",
                                price: subtotalBreakdown.oneTime,
                                bold: false,
                            },
                            {
                                name: discountBreakdown.oneTime ? discountBreakdown.oneTime.name : "Discount",
                                price: discountBreakdown.oneTime ? discountBreakdown.oneTime.price : 0,
                                bold: false,
                            },
                            {
                                name: vatBreakdown.oneTime ? vatBreakdown.oneTime.name : "VAT",
                                price: vatBreakdown.oneTime ? vatBreakdown.oneTime.price : 0,
                                bold: false,
                            },
                            {
                                name: "Total",
                                price: finalCost + (vatBreakdown.oneTime ? vatBreakdown.oneTime.price : 0),
                                bold: true,
                            }
                        ]}
                    />

                    {
                        monthlyCost !== 0 ?
                        <>
                            <h3 className="breakdown-title">Monthly Breakdown</h3>
                            <Table
                                data={monthlyBreakdown}
                                finalData={[
                                    {
                                        name: "Subtotal",
                                        price: subtotalBreakdown.monthly,
                                        bold: false,
                                    },
                                    {
                                        name: discountBreakdown.monthly ? discountBreakdown.monthly.name : "Discount",
                                        price: discountBreakdown.monthly ? discountBreakdown.monthly.price : 0,
                                        bold: false,
                                    },
                                    {
                                        name: vatBreakdown.monthly ? vatBreakdown.monthly.name : "VAT",
                                        price: vatBreakdown.monthly ? vatBreakdown.monthly.price : 0,
                                        bold: false,
                                    },
                                    {
                                        name: "Total",
                                        price: monthlyCost + (vatBreakdown.monthly ? vatBreakdown.monthly.price : 0),
                                        bold: true,
                                    }
                                ]}
                            />
                        </> : null
                    }

                    {
                        annualCost !== 0 ?
                        <>
                            <h3 className="breakdown-title">Annual Breakdown</h3>
                            <Table
                                data={annualBreakdown}
                                finalData={[
                                    {
                                        name: "Subtotal",
                                        price: subtotalBreakdown.annual,
                                        bold: false,
                                    },
                                    {
                                        name: discountBreakdown.annual ? discountBreakdown.annual.name : "Discount",
                                        price: discountBreakdown.annual ? discountBreakdown.annual.price : 0,
                                        bold: false,
                                    },
                                    {
                                        name: vatBreakdown.annual ? vatBreakdown.annual.name : "VAT",
                                        price: vatBreakdown.annual ? vatBreakdown.annual.price : 0,
                                        bold: false,
                                    },
                                    {
                                        name: "Total",
                                        price: annualCost + (vatBreakdown.annual ? vatBreakdown.annual.price : 0),
                                        bold: true,
                                    }
                                ]}
                            />
                        </> : null
                    }

                    <div className="finalcosts-buttons">
                        <button
                            className="pink-button"
                            onClick={downloadQuote}
                        >Download Quote</button>

                        <button
                            className="blue-button"
                            onClick={saveQuote}
                        >Save Quote</button>

                        <button
                            className="transparent-button"
                            onClick={deleteQuote}
                        >Delete Quote</button>
                    </div>
                </div>
            </div>

            
            <SaveWebsiteModalRef ref={SaveWebsiteModalRef} />
            <DownloadQuoteModalRef ref={DownloadQuoteModalRef} />
        </div>
    )
}

const formatter = new Intl.NumberFormat('en-UK', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });


function validateName(name) {
    // or just whitespace (trim)
    if (name.trim() === "") {
        return { valid: false, reason: "Name cannot be empty" };
    }

    // check if name is too short/long
    if (name.length < 3 || name.length > 20) {
        return { valid: false, reason: "Name must be between 3 and 20 characters" };
    }

    // check if name contains invalid characters
    if (!/^[a-zA-Z0-9 ]+$/.test(name)) {
        return { valid: false, reason: "Name must only contain alphanumeric characters and spaces" };
    }

    return { valid: true, reason: null };
}