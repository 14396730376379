import React from "react";
import "./input.css";

export default function Input(props) {
    return (
        <div className="input-container">
            <input
                type="number"
                name={props.name}
                id={props.name}
                min="0"
                value={props.value}
                onChange={(e) => {
                    props.setValue(e.target.value);
                }}
                className={
                    (props.prefix === null ? " " : " prefixed")
                }
            />
            {
                props.prefix !== null ? <span className="prefix">{props.prefix}</span>
                : null
            }
        </div>
    );
}