import { Fragment, useState } from "react";
import { Dialog, Transition } from '@headlessui/react'

export default function SaveWebsiteDialog(props) {
    const [websiteName, setWebsiteName] = useState("");

    return (
        <>
            <Transition appear show={props.open} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto"
                    onClose={props.closeModal}
                >
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                    <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                            <Dialog.Title
                                as="h1"
                                className="text-3xl font-medium leading-6 text-gray-900"
                            >
                                Save website
                            </Dialog.Title>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500 border-t pt-2">
                                If the website name exists, it will be overwritten.
                                </p>
                            </div>

                            <div className="mt-4">
                                <input
                                    type="text"
                                    name="websiteName"
                                    id="websiteName"
                                    placeholder="Website name"
                                    onChange={(e) => {
                                        setWebsiteName(e.target.value);
                                    }}
                                    value={websiteName}
                                    className="w-full px-3 py-2 text-sm text-gray-700 placeholder-gray-400 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    spellCheck="false"
                                />

                                <div className="mt-4"
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-around"
                                    }}
                                >
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-black bg-blue-600 border border-transparent rounded-md hover:bg-blue-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                        onClick={() => {
                                            props.saveWebsite(websiteName);
                                            props.setWebsiteName(websiteName);
                                            props.closeModal();
                                        }}
                                    >
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-4 py-2 ml-2 text-sm font-medium text-gray-700 bg-gray-200 border border-transparent rounded-md hover:bg-red-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                        onClick={props.closeModal}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>

                        </div>
                    </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
      );
}