import { Fragment, useState } from "react";
import { Dialog, Transition } from '@headlessui/react'
import axios from "axios";
import { BarLoader } from "react-spinners";
import { toast } from 'react-toastify';
import { DOMAIN } from "./urlcfg";

import "./downloadQuoteDialog.css";
import { hostingTypes, sslTypes } from "./constants";

const REGEX = {
    "URL": /^((http|https):\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
    "EMAIL": /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
};

function capitalizeString(str) {
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

function SitemapItem(props) {
    return (
        <div className="sitemap-item noselect">
            <div className="sitemap-item-name">
                {props.name}
                <div className="sitemap-item-remove"
                    onClick={() => props.removeItem()}
                >x</div>
            </div>
        </div>
    )
}

export default function DownloadQuoteDialog(props) {
    const [websiteURL, setWebsiteURL] = useState("");
    const [clientName, setClientName] = useState("");
    const [clientCompany, setClientCompany] = useState("");
    const [refCode, setRefCode] = useState("");
    const [creatorName, setCreatorName] = useState("");
    const [creatorEmail, setCreatorEmail] = useState("");
    const [newsArticles, setNewsArticles] = useState(0);
    const [links, setLinks] = useState(0);
    const [sitemapInput, setSitemapInput] = useState("");
    const [commencementTimescale, setCommencementTimescale] = useState("");
    const [completionTimescale, setCompletionTimescale] = useState("");

    const [siteMap, setSiteMap]  = useState([
        {
            "name": "Home",
            "features": []
        },
        {
            "name": "About Us",
            "features": []
        },
        {
            "name": "Services",
            "features": []
        },
        {
            "name": "Contact",
            "features": []
        }
    ]);

    const addSitemapItem = () => {
        let name = sitemapInput;

        if (name === "") {
            toast.error("Please enter a name");
            return;
        }

        let newSiteMap = [...siteMap];
        newSiteMap.push({
            "name": capitalizeString(name),
            "features": []
        });

        setSiteMap(newSiteMap);

        setSitemapInput("");
    }

    const removeSitemapItem = (index) => {
        let newSiteMap = [...siteMap];
        newSiteMap.splice(index, 1);

        setSiteMap(newSiteMap);
    }

    const validateData = (data) => {
        // MT XXX
        return {
            valid: true,
            message: ""
        }

        // URL must be valid regex
        if (!REGEX.URL.test(data.websiteURL)) {
            return {
                valid: false,
                message: "Please enter a valid URL"
            }
        }

        // Client name, company, creatorName, creatorEmail must not be empty
        if (data.clientName === "" || data.clientCompany === "" || data.creatorName === "" || data.creatorEmail === "") {
            return {
                valid: false,
                message: "Please fill in all fields"
            }
        }

        // creatorEmail must be valid regex
        if (!REGEX.EMAIL.test(data.creatorEmail)) {
            return {
                valid: false,
                message: "Please enter a valid email"
            }
        }

        // sitemap must have at least 1 item
        if (data.sitemap.length === 0) {
            return {
                valid: false,
                message: "Please add at least 1 page to the sitemap"
            }
        }

        if (data.refCode.length == 0) {
            return {
                valid: false,
                message: "Please enter a reference"
            }
        }

        return {
            valid: true,
            message: ""
        }
    }

    const downloadQuote = () => {
        console.log("Downloading quote", props);
        // collect data

        let data = {
            "websiteURL": websiteURL,
            "clientName": clientName,
            "clientCompany": clientCompany,
            "refCode": refCode,
            "commencementTimescale": commencementTimescale,
            "completionTimescale": completionTimescale,
            "creatorName": creatorName,
            "creatorEmail": creatorEmail,
            "sitemap": siteMap,
            "date": new Date().toLocaleDateString(),
            "pages": props.pages,
            "subtotalCost": props.subtotalCost.toFixed(2),
            "discount": props.discountCost.toFixed(2),
            "vat": props.vatCost.toFixed(2),
            "finalCost": props.finalCost.toFixed(2),
            "oneTimeBreakdown": props.oneTimeBreakdown,
            "monthlyBreakdown": props.monthlyBreakdown,
            "annualBreakdown": props.annualBreakdown,
            "additions": props.additions,
            "sslType": props.sslType,
            "hostingType": props.hostingType,
            "hostingCost": hostingTypes[props.hostingType].annualCost,
            "sslCost": props.ecommerce ? sslTypes[props.sslType].eCommerceAnnualCost : sslTypes[props.sslType].annualCost,
            "carePackage": props.carePackage,
            "seoPackage": props.seoPackage,
            "ecommerce": props.ecommerce
        };

        // validate first
        const isValid = validateData(data);
        if (!isValid.valid) {
            toast.error(isValid.message);
            return;
        }

        console.log(data);

        // send data
        axios.post(`${DOMAIN}/genQuote`, data, {
            responseType: 'blob'
        }).then((response) => {
            // create a blob from the response
            const fileBlob = new Blob([response.data], { type: response.headers['content-type'] });

            // create a temporary URL for the blob
            const fileURL = window.URL.createObjectURL(fileBlob);

            // create a link element
            const downloadLink = document.createElement('a');
            downloadLink.href = fileURL;
            downloadLink.setAttribute('download', 'quote.docx');

            // add the link to the DOM
            document.body.appendChild(downloadLink);

            // click the link
            downloadLink.click();

            // remove the link from the DOM
            document.body.removeChild(downloadLink);

            // remove the temporary URL
            window.URL.revokeObjectURL(fileURL);

            downloadLink.remove();

        }).catch((e) => {
            toast.error("Error downloading quote");
            console.log(e);
        });
    }

    return (
        <Transition appear show={props.open} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={props.closeModal}
            >
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                <div className="min-h-screen px-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                    className="inline-block h-screen align-middle"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className="inline-block w-full p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl dq">
                        <Dialog.Title
                            as="h1"
                            className="text-3xl font-medium leading-6 text-gray-900 mb-3"
                        >
                            Download {props.websiteName} quote
                        </Dialog.Title>

                        <div className="split-dq">
                            <div className="left-dq">
                                <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    Website URL
                                </p>
                                <input
                                    type="text"
                                    className="w-full mt-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 downloadQuoteInput"
                                    placeholder="https://www.example.com"
                                    onChange={(e) => setWebsiteURL(e.target.value)}
                                    value={websiteURL}
                                />

                                <p className="mt-4 text-sm text-gray-500">
                                    Client name
                                </p>
                                <input
                                    type="text"
                                    className="w-full mt-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 downloadQuoteInput"
                                    placeholder="John Doe"
                                    onChange={(e) => setClientName(e.target.value)}
                                    value={clientName}
                                />

                                <p className="mt-4 text-sm text-gray-500">
                                    Client company
                                </p>
                                <input
                                    type="text"
                                    className="w-full mt-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 downloadQuoteInput"
                                    placeholder="Example Company"
                                    onChange={(e) => setClientCompany(e.target.value)}
                                    value={clientCompany}
                                />

                                <p className="mt-4 text-sm text-gray-500">
                                    Reference code
                                </p>
                                <input
                                    type="text"
                                    className="w-full mt-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 downloadQuoteInput"
                                    placeholder="ABC123"
                                    onChange={(e) => setRefCode(e.target.value)}
                                    value={refCode}
                                />

                                <p className="mt-4 text-sm text-gray-500">
                                    Proposal creator
                                </p>
                                <input
                                    type="text"
                                    className="w-full mt-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 downloadQuoteInput"
                                    placeholder="John Doe"
                                    onChange={(e) => setCreatorName(e.target.value)}
                                    value={creatorName}
                                />

                                <p className="mt-4 text-sm text-gray-500">
                                    Creator email
                                </p>
                                {/* include @silvertoad.co.uk suffix */}
                                <input
                                    type="text"
                                    className="w-full mt-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 downloadQuoteInput"
                                    placeholder="bob"
                                    onChange={(e) => setCreatorEmail(e.target.value)}
                                    value={creatorEmail}
                                />

                                <p className="mt-4 text-sm text-gray-500">
                                    Commencement Timescale
                                </p>
                                <input
                                    type="text"
                                    className="w-full mt-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 downloadQuoteInput"
                                    placeholder="e.g. 1-2 weeks"
                                    onChange={(e) => setCommencementTimescale(e.target.value)}
                                    value={commencementTimescale}
                                />

                                <p className="mt-4 text-sm text-gray-500">
                                    Completion Timescale
                                </p>
                                <input
                                    type="text"
                                    className="w-full mt-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 downloadQuoteInput"
                                    placeholder="e.g. 6-8 weeks"
                                    onChange={(e) => setCompletionTimescale(e.target.value)}
                                    value={completionTimescale}
                                />

                                

                            </div>
                            </div>
                            <div className="right-dq">
                                {/* sitemap */}
                                <div className="mt-2">
                                    <p className="mt-4 text-sm text-gray-500">
                                        Add new page to sitemap
                                    </p>

                                    <div className="dq-input">
                                        <input
                                            type="text"
                                            className="w-full mt-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 downloadQuoteInputBox"
                                            placeholder="Page name"
                                            onChange={(e) => setSitemapInput(e.target.value)}
                                            value={sitemapInput}
                                        />

                                        <button 
                                            className="mt-4 w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white downloadQuoteButton"
                                            onClick={() => addSitemapItem()}
                                        >
                                            Add page
                                        </button>

                                    </div>

                                    <br />
                                    
                                    <p className="text-sm text-gray-500">
                                        Sitemap
                                    </p>
                                    <div className="flex flex-row mt-2">
                                        <div className="sitemap">
                                            {
                                                siteMap.map((item, index) => {
                                                    return (
                                                        <SitemapItem
                                                            key={index}
                                                            name={item.name}
                                                            features={item.features}
                                                            removeItem={() => removeSitemapItem(index)}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* download button */}
                        <div className="mt-4">
                            <button
                                className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white downloadQuoteButton"
                                onClick={() => downloadQuote()}
                            >
                                Download quote
                            </button>
                        </div>

                    </div>
                </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}