import React from "react";
import { useState } from "react";
import "./webdetails.css";
import "./checkboxSelect.css";

import ECommerce from "./eCommerce";
import Input from "./Input";

export default function WebDetails(props) {
    return (
        <div className="webdetails price-section">
            <span className="label">Content</span>

            {/* inputs for pages, posts, taxonomies, contact forms */}
            <div className="webdetails-inputs">
                <div className="count-input">
                    <label htmlFor="pages">Number of pages</label>
                    <Input
                        name="pages"
                        value={props.pages}
                        setValue={props.setPages}
                    />
                </div>

                <div className="count-input">
                    <label htmlFor="posts">Number of posts</label>
                    <Input
                        name="posts"
                        value={props.posts}
                        setValue={props.setPosts}
                    />
                </div>

                {/* <div className="count-input">
                    <label htmlFor="taxonomies">Number of taxonomies</label>
                    <Input
                        name="taxonomies"
                        value={props.taxonomies}
                        setValue={props.setTaxonomies}
                    />
                </div> */}

                <div className="count-input">
                    <label htmlFor="contactForms">Number of contact forms</label>
                    <Input
                        name="contactForms"
                        value={props.contactForms}
                        setValue={props.setContactForms}
                    />
                </div>
            </div>

            <ECommerce
                products={props.products}
                setProducts={props.setProducts}
                productCategories={props.productCategories}
                setProductCategories={props.setProductCategories}
                eCommerce={props.eCommerce}
                setECommerce={props.setECommerce}
            />
        </div>
    )
}