import { Fragment, useState } from "react";
import { Dialog, Transition } from '@headlessui/react'
import axios from "axios";
import { BarLoader } from "react-spinners";
import { toast } from 'react-toastify';
import { DOMAIN } from "./urlcfg";

export default function LoadWebsiteDialog(props) {
    const loadWebsite = (name) => {
        console.log("Requesting website " + name + " from server..")
        axios.get(`${DOMAIN}/websites/${name}`)
            .then(res => {
                if (!res.data.success) {
                    toast.error(`Failed to load website!`, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    return;
                }

                props.setData(res.data.website.data, name);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const deleteWebsite = (name) => {
        console.log("Deleting website " + name + " from server..")

        props.setLoaded(false);

        axios.delete(`${DOMAIN}/websites/${name}`)
            .then(res => {
                if (!res.data.success) {
                    toast.error(`Failed to delete website!`, {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    props.setLoaded(true);
                    return;
                }

                toast.success(`Deleted website!`, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                // Reload websites
                props.loadWebsites();
            })
            .catch(err => {
                console.error(err);
                toast.error(`Failed to delete website!`, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
    }

    return (
        <Transition appear show={props.open} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={props.closeModal}
            >
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                <div className="min-h-screen px-4 text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                    className="inline-block h-screen align-middle"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                        <Dialog.Title
                            as="h1"
                            className="text-3xl font-medium leading-6 text-gray-900"
                        >
                            Load a website
                        </Dialog.Title>

                        <div className="mt-4">
                            <div className="flex flex-col">
                                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-200">
                                                <thead className="bg-gray-50">
                                                    <tr>
                                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                            Name
                                                        </th>
                                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                            Date
                                                        </th>
                                                        <th scope="col" className="relative px-6 py-3">
                                                            <span className="sr-only">Load</span>
                                                        </th>
                                                        <th scope="col" className="relative px-6 py-3">
                                                            <span className="sr-only">Delete</span>
                                                        </th>
                                                    </tr>

                                                    {props.loaded ? props.websites.map((website, index) => {
                                                        return (
                                                            <tr key={index} className="bg-white">
                                                                <td className="px-0 py-4 whitespace-nowrap">
                                                                    <div className="flex items-center">
                                                                        <div className="ml-4">
                                                                            <div className="text-sm font-medium text-gray-900">
                                                                                {website.name}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="px-4 py-4 whitespace-nowrap">
                                                                    <div className="text-sm text-gray-900">{handleDate(website.date)}</div>
                                                                </td>

                                                                <td className="px-4 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                                    <button onClick={() => loadWebsite(website.name)} className="text-indigo-600 hover:text-indigo-900">Load</button>
                                                                </td>

                                                                <td className="px-4 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                                    <button onClick={() => deleteWebsite(website.name)} className="text-red-600 hover:text-red-900">Delete</button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }) :<>
                                                        <tr className="bg-white">
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="flex items-center">
                                                                    <div className="ml-4">
                                                                        <div className="text-sm font-medium text-gray-900">
                                                                            Loading...
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap">    
                                                                <div className="text-sm text-gray-900">
                                                                    <BarLoader color={"#3B82F6"} loading={true} height={10} width={100} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>}
                                                </thead>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}

function handleDate(date) {
    const d = new Date(date);
    
    // if its within the last 4 days
    if (d.getTime() > new Date().getTime() - 345600000) {
        // if its today
        if (d.getDate() === new Date().getDate()) {
            // if its within the last hour
            if (d.getTime() > new Date().getTime() - 3600000) {
                // if its within the last minute
                if (d.getTime() > new Date().getTime() - 60000) {
                    // display seconds
                    return `${Math.floor((new Date().getTime() - d.getTime()) / 1000)} seconds ago`;
                } else {
                    // display minutes
                    return `${Math.floor((new Date().getTime() - d.getTime()) / 60000)} minutes ago`;
                }
            }
            // if its within the last 24 hours
            else if (d.getTime() > new Date().getTime() - 86400000) {
                // display hours
                return `${Math.floor((new Date().getTime() - d.getTime()) / 3600000)} hours ago`;
            }
            // if its within the last 3 days
            else if (d.getTime() > new Date().getTime() - 259200000) {
                // display days
                return `${Math.floor((new Date().getTime() - d.getTime()) / 86400000)} days ago`;
            }
        }
        // if its yesterday
        else if (d.getDate() === new Date().getDate() - 1) {
            // display yesterday
            return "Yesterday";
        }
        // if its within the last 3 days
        else if (d.getTime() > new Date().getTime() - 259200000) {
            // display days
            return `${Math.floor((new Date().getTime() - d.getTime()) / 86400000)} days ago`;
        }
    } else {
        // display the date
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    }
}